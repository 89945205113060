import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const Footer = () => (
    <footer className="container mx-auto py-16 px-3 mt-48 mb-8 text-gray-800">
    <div className="flex -mx-3">
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">About Us</h2>
        <p className="mt-5">We are a Slavic Baptist Church located in Wasilla, Alaska.</p>
      </div>
      <div className="flex-1 px-3">
        {/*<h2 className="text-lg font-semibold">Important Links</h2>*/}
        {/*<ul className="mt-4 leading-loose">*/}
        {/*  <li><a href="https://codebushi.com">Terms & Conditions</a></li>*/}
        {/*  <li><a href="https://codebushi.com">Privacy Policy</a></li>*/}
        {/*</ul>*/}
      </div>
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Navigation</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <AnchorLink className="px-4" href="#services">Services</AnchorLink>
          </li>
          <li>
            <AnchorLink className="px-4" href="#directions">Directions</AnchorLink>
          </li>
          <li>
            <AnchorLink className="px-4" href="#contact">Contact Us</AnchorLink>
          </li>
        </ul>
      </div>
    </div>
  </footer>
);

export default Footer;
